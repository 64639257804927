<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap27EnumIotaAndBitmask"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 27: Enum, Iota & Bitmask" image-name="enum-iota.jpg" image-alt="Enum, Iota & Bitmask"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is an enum?</p></li>
<li><p>How to create an enum with Go.</p></li>
<li><p>What is iota, and how to use it?</p></li>
<li><p>What are bitwise operators, and how to use them?</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Enum</p></li>
<li><p>Enumeration type</p></li>
<li><p>iota</p></li>
<li><p>index</p></li>
<li><p>Byte, bits</p></li>
<li><p>Binary</p></li>
<li><p>Bitmask, Bitflag</p></li>
</ul>
<div id="enum-definition" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Enum definition <a href="#enum-definition"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>An “enum” (or enumeration data type) is a <strong>data type</strong> that consists of a set of “values that are explicitly defined by the programmer”<b-link class="citation" data-cites="institute1990ieee" href="#institute1990ieee" >[@institute1990ieee]</b-link></p>
<p>For example, days of the week are an enum. There are seven days of the week and not more.</p>
<div id="go-and-enums" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Go and enums <a href="#go-and-enums"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The language (at least in its version 1) does not have specific enumeration types. However, we can still build a type that offers the same features as enums.</p>
<div id="building-a-type-that-can-be-used-as-enum." class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Building a type that can be used “as” enum. <a href="#building-a-type-that-can-be-used-as-enum."><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Let’s take the example of HTTP methods.</p>
<pre v-highlightjs><code class="go" v-pre >// enum-iota-bitmasks/type-as-enum/main.go 

type HTTPMethod int

const (
    GET     HTTPMethod = 0
    POST    HTTPMethod = 1
    PUT     HTTPMethod = 2
    DELETE  HTTPMethod = 3
    PATCH   HTTPMethod = 4
    HEAD    HTTPMethod = 5
    OPTIONS HTTPMethod = 6
    TRACE   HTTPMethod = 7
    CONNECT HTTPMethod = 8
)</code></pre>
<ul>
<li><p>First, we declare a new type <span v-highlightjs><code class="go" v-pre style="display: inline">HTTPMethod</code></span> (its underlying type is <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span>).</p></li>
<li><p>Then we create nine constants of type <span v-highlightjs><code class="go" v-pre style="display: inline">HTTPMethod</code></span></p></li>
<li><p>Each constant is of type <span v-highlightjs><code class="go" v-pre style="display: inline">HTTPMethod</code></span> and is named like the HTTP method its designates.</p></li>
</ul>
<div id="why-integers" class="anchor"></div>
<h3 data-number="5.0.1"><span class="header-section-number">5.0.1</span> Why integers? <a href="#why-integers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>You are free to use an underlying type different from <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span>.</p>
<p>However, this is a common practice to use integers.</p>
<ul>
<li><p>It’s generally more efficient to compare an integer with another integer instead of comparing a string with another string</p></li>
<li><p>You can use iota. (see next sections)</p></li>
</ul>
<div id="usage-example" class="anchor"></div>
<h3 data-number="5.0.2"><span class="header-section-number">5.0.2</span> Usage Example <a href="#usage-example"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Imagine that you have a function that handles HTTP requests.</p>
<p>In this function, you expect the user to give you an HTTP method, and you expect to handle a predefined set of methods. Your function can have a parameter of type <span v-highlightjs><code class="go" v-pre style="display: inline">HTTPMethod</code></span> (instead of <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span>) :</p>
<pre v-highlightjs><code class="go" v-pre >func handle(method HTTPMethod, headers map[string]string, uri string) {}</code></pre>
<p>Inside the function body, you can adapt the behavior of your handler by using the enum values :</p>
<pre v-highlightjs><code class="go" v-pre >func handle(method HTTPMethod, headers map[string]string, uri string) {
    if method == GET {
        fmt.Println(&quot;the method is get&quot;)
    } else {
        fmt.Println(&quot;the method is not get&quot;)
    }
}</code></pre>
<p>Enums types can also be used in structs (like any other types) :</p>
<pre v-highlightjs><code class="go" v-pre >// enum-iota-bitmasks/type-as-enum/main.go 

type HTTPRequest struct {
    method  HTTPMethod
    headers map[string]string
    uri     string
}
func main() {
    r := HTTPRequest{method: GET, headers: map[string]string{&quot;Accept&quot;: &quot;application/json&quot;}, uri: &quot;/prices&quot;}
    fmt.Println(r)
}</code></pre>
<div id="a-perfectible-solution" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> A perfectible solution <a href="#a-perfectible-solution"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The solution proposed in the previous section is not perfect. Here is why :</p>
<ul>
<li><strong>Any</strong> <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span> can be a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">HTTPMethod</code></span> :</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >lolMethod := HTTPMethod(42)
headers := make(map[string]string)
handle(lolMethod,headers,&quot;/prices&quot; )</code></pre>
<p>We created a variable <span v-highlightjs><code class="go" v-pre style="display: inline">lolMethod</code></span> which is of type <span v-highlightjs><code class="go" v-pre style="display: inline">HTTPMethod</code></span> and the code will compile.</p>
<ul>
<li>When we print an <span v-highlightjs><code class="go" v-pre style="display: inline">HTTPMethod</code></span> the output is an <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span> :</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >log.Println(GET)
// &gt; 0</code></pre>
<ul>
<li>When marshaled to JSON, a value of type <span v-highlightjs><code class="go" v-pre style="display: inline">HTTPMethod</code></span> will be printed as an int ...</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >type HTTPRequest struct {
    Method  HTTPMethod `json:&quot;method&quot;`
    Headers map[string]string `json:&quot;headers&quot;`
    Uri     string `json:&quot;uri&quot;`
}
r := HTTPRequest{Method: GET, Headers: map[string]string{&quot;Accept&quot;: &quot;application/json&quot;}, Uri: &quot;/prices&quot;}
marshaled, err  := json.Marshal(r)
if err != nil {
    panic(err)
}</code></pre>
<p>Will produce the following JSON string:</p>
<pre v-highlightjs><code class="go" v-pre >{
  &quot;method&quot;: 0,
  &quot;headers&quot;: {
    &quot;Accept&quot;: &quot;application/json&quot;
  },
  &quot;uri&quot;: &quot;/prices&quot;
}</code></pre>
<p>The value <span v-highlightjs><code class="go" v-pre style="display: inline">0</code></span> might be interpreted as an error. Instead, we want to output <span v-highlightjs><code class="go" v-pre style="display: inline">"GET"</code></span> .</p>
<ul>
<li>When we want to unmarshal a JSON string, we might encounter an error :</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >jsonB := []byte(&quot;{\&quot;method\&quot;:\&quot;GET\&quot;,\&quot;headers\&quot;:{\&quot;Accept\&quot;:\&quot;application/json\&quot;},\&quot;uri\&quot;:\&quot;/prices\&quot;}&quot;)
req := HTTPRequest{}
err = json.Unmarshal(jsonB,&amp;req)
if err != nil {
    panic(err)
}</code></pre>
<p>We got a panic :</p>
<pre v-highlightjs><code class="go" v-pre >panic: json: cannot unmarshal string into Go struct field HTTPRequest.method of type main.HTTPMethod</code></pre>
<p>This is perfectly normal, we have a string as input, and we want to convert it to an int. One solution could be to change the underlying type to string.</p>
<div id="enum-libraries" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Enum libraries <a href="#enum-libraries"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To solve the previous issues :</p>
<ul>
<li><p>We need to be able to <strong>check</strong> if a value is part of the enum</p>
<ul>
<li>We need a method that validates a value</li>
</ul></li>
<li><p>We need to be able to <strong>print</strong> an element correctly</p>
<ul>
<li>We need to implement the <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Stringer</code></span> interface</li>
</ul></li>
<li><p>We need to be able to <strong>marshal</strong> correctly an enum value into JSON (an maybe into another format)</p>
<ul>
<li>We need to implement the <span v-highlightjs><code class="go" v-pre style="display: inline">json.Marshaler</code></span> interface</li>
</ul></li>
<li><p>We need to be able to <strong>unmarshal</strong> correctly an enum value from JSON (an maybe from another format)</p>
<ul>
<li>We need to implement the <span v-highlightjs><code class="go" v-pre style="display: inline">json.Unmarshaler</code></span> interface</li>
</ul></li>
</ul>
<p>We can implement those interfaces :</p>
<pre v-highlightjs><code class="go" v-pre >// enum-iota-bitmasks/enum-implementations/main.go 

type HTTPMethod int

func (h HTTPMethod) IsValid() bool {
    panic(&quot;implement me&quot;)
}

func (h HTTPMethod) String() string {
    panic(&quot;implement me&quot;)
}

func (h HTTPMethod) UnmarshalJSON(bytes []byte) error {
    panic(&quot;implement me&quot;)
}

func (h HTTPMethod) MarshalJSON() ([]byte, error) {
    panic(&quot;implement me&quot;)
}</code></pre>
<p>However, this is tedious. We can use a library to do that for us. After a quick search on GitHub, it seems that two libraries emerge :</p>
<ul>
<li><p>https://github.com/alvaroloes/enumer</p></li>
<li><p>https://github.com/abice/go-enum</p></li>
</ul>
<p>They will generate those methods for you. They offer a command-line interface.</p>
<p>Note that the support for enums is a feature request for version 2 of the language.</p>
<div id="iota" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Iota <a href="#iota"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In the previous section:</p>
<ul>
<li><p>We needed to assign an integer to each constant.</p></li>
<li><p>The type <span v-highlightjs><code class="go" v-pre style="display: inline">HTTPMethod</code></span> is also written on each line.</p></li>
</ul>
<p>To avoid those two tasks, we can modify our enum to use <span v-highlightjs><code class="go" v-pre style="display: inline">iota</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >// enum-iota-bitmasks/iota/main.go
package main

import &quot;fmt&quot;

type HTTPMethod int

const (
    GET     HTTPMethod = iota
    POST    HTTPMethod = iota
    PUT     HTTPMethod = iota
    DELETE  HTTPMethod = iota
    PATCH   HTTPMethod = iota
    HEAD    HTTPMethod = iota
    OPTIONS HTTPMethod = iota
    TRACE   HTTPMethod = iota
    CONNECT HTTPMethod = iota
)

func main() {
    fmt.Println(PUT)
    // 2
}</code></pre>
<p>We can simplify this code. It’s possible to avoid repeating <span v-highlightjs><code class="go" v-pre style="display: inline">HTTPMethod = iota</code></span> each time by using the implicit repetition property. This property is very handy; it says that inside a constant set (<span v-highlightjs><code class="go" v-pre style="display: inline">const(...)</code></span>) you can assign a value just to the <strong>first</strong> const:</p>
<pre v-highlightjs><code class="go" v-pre >// enum-iota-bitmasks/iota-improvement/main.go 

const (
    GET HTTPMethod = iota
    POST
    PUT
    DELETE
    PATCH
    HEAD
    OPTIONS
    TRACE
    CONNECT
)</code></pre>
<div id="how-does-iota-work" class="anchor"></div>
<h3 data-number="8.0.1"><span class="header-section-number">8.0.1</span> How does iota work <a href="#how-does-iota-work"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<ul>
<li><p>“iota represents successive untyped integer constants”<b-link class="citation" data-cites="go-specs" href="#go-specs" >[@go-specs]</b-link></p></li>
<li><p>It means that iota is always an integer; it’s impossible to use iota to construct floats values (for instance).</p></li>
<li><p>“Its value is the index of the respective ConstSpec in that constant declaration”<b-link class="citation" data-cites="go-specs" href="#go-specs" >[@go-specs]</b-link></p></li>
</ul>
<p>The value of iota is determined by the index of the constant in the constant declaration. In the previous example, POST is the second constant then it has a value of 1. Why 1? Why not 2? That’s because of the third property of iota :</p>
<ul>
<li>it starts at zero <b-link class="citation" data-cites="go-specs" href="#go-specs" >[@go-specs]</b-link></li>
</ul>
<p>The initial value of iota is zero. Example :</p>
<pre v-highlightjs><code class="go" v-pre >type TestEnum int

const (
    First TestEnum = iota
    Second
)

fmt.Println(First)
// 0</code></pre>
<p>iota is initialized with zero. If you want that your first enum element starts with something other than zero, you can assign a different value to the initial constant :</p>
<pre v-highlightjs><code class="go" v-pre >type TestEnum int

const (
    First TestEnum = iota +4
    Second
)

fmt.Println(First)
// 4 (0+4)</code></pre>
<p>You can also multiply iota by an integer :</p>
<pre v-highlightjs><code class="go" v-pre >type TestEnum int

const (
    First TestEnum = iota * 3
    Second
)

fmt.Println(Second)
// 3 (1*3)</code></pre>
<figure>
<b-img :src="require('@/assets/images/iota_value.png')" alt="iota value"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">iota value</figcaption>
</figure>
<div id="byte-bit" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Byte / Bit <a href="#byte-bit"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A byte is composed of 8 bits of memory. A bit is a binary digit. It’s either equal to 0 or 1.</p>
<figure>
<b-img :src="require('@/assets/images/bit_byte.png')" alt="Bit and bytes[fig:Bit-and-bytes]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Bit and bytes<span id="fig:Bit-and-bytes" label="fig:Bit-and-bytes">[fig:Bit-and-bytes]</span></figcaption>
</figure>
<p>The indexing of bits is not natural; we start counting from right to left (and not left to right).</p>
<div id="operations-on-bits" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Operations on bits <a href="#operations-on-bits"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The first step before jumping to bitwise operators is to learn how to print the binary representation of a number.</p>
<div id="print-the-bits" class="anchor"></div>
<h2 data-number="10.1"><span class="header-section-number">10.1</span> Print the bits <a href="#print-the-bits"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To print the bits of a number, you can use the fmt.Printf() function with the format specifier <strong>%08b.</strong>08 means that we will print the binary value on 8 bits.</p>
<pre v-highlightjs><code class="go" v-pre >// enum-iota-bitmasks/print-bits/main.go
package main

import &quot;fmt&quot;

func main() {
    var x uint8
    x = 1
    fmt.Printf(&quot;%08b\n&quot;, x)
    //00000001
    x = 2
    fmt.Printf(&quot;%08b\n&quot;, x)
    //00000010
    x = 255
    fmt.Printf(&quot;%08b\n&quot;, x)
    //11111111
}</code></pre>
<p>In the previous code listing, we define X, an unsigned integer stored on 8 bits. X can take the values from 0 to 255. We assign to x the value 1, then 2, and 255. Each time we are printing the binary representation.</p>
<div id="bitwise-operations" class="anchor"></div>
<h2 data-number="10.2"><span class="header-section-number">10.2</span> Bitwise operations <a href="#bitwise-operations"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To do operations on bits, we need operators. We call those operators “bitwise operators” In this section, we will go through all of them. Operations on bits can be intimidating initially, but they are nothing else than boolean logic. If you are already familiar with AND, OR, XOR, etc... it will be easy to understand.</p>
<p>An operation can be decomposed into three elements :</p>
<ul>
<li><p>The operator</p></li>
<li><p>The operands</p></li>
<li><p>The result</p></li>
</ul>
<p>For instance, in the operations <span v-highlightjs><code class="go" v-pre style="display: inline">x | y = z</code></span> <span v-highlightjs><code class="go" v-pre style="display: inline">x</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">y</code></span> are operands</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">|</code></span> is the operator</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">z</code></span> is the result For each operator, the same logic applies. Operations are performed independently on each bit of the operands.</p>
<p>Bitwise operators do not have to be mixed up with logical operators (&amp;&amp;, || and !). Those operators are used to compare boolean values from left to right.</p>
<ul>
<li>Warning: you can use the following operators with integers only.</li>
</ul>
<div id="and-operator" class="anchor"></div>
<h3 data-number="10.2.1"><span class="header-section-number">10.2.1</span> AND operator : <span v-highlightjs><code class="go" v-pre style="display: inline">&amp;</code></span> <a href="#and-operator"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<ul>
<li>If <span v-highlightjs><code class="go" v-pre style="display: inline">x</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">y</code></span> are two bits</li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">x &amp; y</code></span> is equal to 1 only if <span v-highlightjs><code class="go" v-pre style="display: inline">x</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">y</code></span> are equal to 1.</p>
<ul>
<li><p>Otherwise <span v-highlightjs><code class="go" v-pre style="display: inline">x &amp; y</code></span> is equal to 0. J</p></li>
<li><p>ust remember that it’s only true if <strong>both</strong> operands are true.</p></li>
</ul>
<table>
<caption>AND bitwise operator</caption>
<thead>
<tr class="header">
<th style="text-align: center;">x</th>
<th style="text-align: center;">y</th>
<th style="text-align: center;">x&amp;y</th>
</tr>
</thead>
<tbody>
<tr class="odd">
<td style="text-align: center;">0</td>
<td style="text-align: center;">0</td>
<td style="text-align: center;">0</td>
</tr>
<tr class="even">
<td style="text-align: center;">0</td>
<td style="text-align: center;">1</td>
<td style="text-align: center;">0</td>
</tr>
<tr class="odd">
<td style="text-align: center;">1</td>
<td style="text-align: center;">1</td>
<td style="text-align: center;">1</td>
</tr>
<tr class="even">
<td style="text-align: center;">1</td>
<td style="text-align: center;">0</td>
<td style="text-align: center;">0</td>
</tr>
</tbody>
</table>
<p>Let’s take an example :</p>
<pre v-highlightjs><code class="go" v-pre >// enum-iota-bitmasks/bitwise-operations/main.go 

var x, y, z uint8
x = 1 // 00000001
y = 2 // 00000010
z = x &amp; y

// print in binary
fmt.Printf(&quot;%08b\n&quot;, z)
// 00000000

// print in base 10
fmt.Printf(&quot;%d\n&quot;, z)
// 0</code></pre>
<p>3 integers variables are created (<span v-highlightjs><code class="go" v-pre style="display: inline">x</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">y</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">z</code></span>). Then we store <span v-highlightjs><code class="go" v-pre style="display: inline">x &amp; y</code></span> in the variable <span v-highlightjs><code class="go" v-pre style="display: inline">z</code></span>.</p>
<figure>
<b-img :src="require('@/assets/images/and_bitwise.png')" alt="AND bitwise operation example[fig:AND-bitwise-operation]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">AND bitwise operation example<span id="fig:AND-bitwise-operation" label="fig:AND-bitwise-operation">[fig:AND-bitwise-operation]</span></figcaption>
</figure>
<p>As shown in figure <a href="#fig:AND-bitwise-operation" data-reference-type="ref" data-reference="fig:AND-bitwise-operation">2</a> we take each bit individually, and then we are calculating the result of every; that’s single operation : <span v-highlightjs><code class="go" v-pre style="display: inline">1 &amp; 0 = 0</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">0 &amp; 1 = 0</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">0 &amp; 0 = 0</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">0 &amp; 0 = 0</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">0 &amp; 0 = 0</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">0 &amp; 0 = 0</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">0 &amp; 0 = 0</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">0 &amp; 0 = 0</code></span> Note that we begin by the right side. Get used to it; that’s the rule when you manipulate bits.</p>
<p>Let’s take another example :</p>
<pre v-highlightjs><code class="go" v-pre >// enum-iota-bitmasks/bitwise-operation-and/main.go 

var x, y, z uint8
x = 200 // 11001000
fmt.Printf(&quot;%08b\n&quot;, x)

y = 100 // 01100100
fmt.Printf(&quot;%08b\n&quot;, y)

z = x &amp; y

// print in binary
fmt.Printf(&quot;%08b\n&quot;, z)
// 01000000

// print in base 10
fmt.Printf(&quot;%d\n&quot;, z)
// 64</code></pre>
<p>In the figure <a href="#fig:AND-ex-2" data-reference-type="ref" data-reference="fig:AND-ex-2">3</a> you can see the detail of the operation <span class="math inline">200\&amp;100</span>.</p>
<figure>
<b-img :src="require('@/assets/images/200_and_100.png')" alt="AND bitwise operation example[fig:AND-ex-2]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">AND bitwise operation example<span id="fig:AND-ex-2" label="fig:AND-ex-2">[fig:AND-ex-2]</span></figcaption>
</figure>
<div id="or-operator" class="anchor"></div>
<h3 data-number="10.2.2"><span class="header-section-number">10.2.2</span> OR operator : <span v-highlightjs><code class="go" v-pre style="display: inline">|</code></span> <a href="#or-operator"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">x | y</code></span> is equal to 1 if :</p>
<ul>
<li><p>one of the operands is equal to 1</p></li>
<li><p>both operands are equal to 1.</p></li>
</ul>
<table>
<caption>OR bitwise operator</caption>
<thead>
<tr class="header">
<th style="text-align: center;">x</th>
<th style="text-align: center;">y</th>
<th style="text-align: center;">x|y</th>
</tr>
</thead>
<tbody>
<tr class="odd">
<td style="text-align: center;">0</td>
<td style="text-align: center;">0</td>
<td style="text-align: center;">0</td>
</tr>
<tr class="even">
<td style="text-align: center;">0</td>
<td style="text-align: center;">1</td>
<td style="text-align: center;">1</td>
</tr>
<tr class="odd">
<td style="text-align: center;">1</td>
<td style="text-align: center;">1</td>
<td style="text-align: center;">1</td>
</tr>
<tr class="even">
<td style="text-align: center;">1</td>
<td style="text-align: center;">0</td>
<td style="text-align: center;">1</td>
</tr>
</tbody>
</table>
<p>Here is an example in go :</p>
<pre v-highlightjs><code class="go" v-pre >// enum-iota-bitmasks/bitwise-or/main.go 

var x, y, z uint8
x = 200 // 11001000
fmt.Printf(&quot;%08b\n&quot;, x)

y = 100 // 01100100
fmt.Printf(&quot;%08b\n&quot;, y)

z = x | y

// print in binary
fmt.Printf(&quot;%08b\n&quot;, z)
// 11101100

// print in base 10
fmt.Printf(&quot;%d\n&quot;, z)
// 236</code></pre>
<figure>
<b-img :src="require('@/assets/images/or_bitwise.png')" alt="OR bitwise operation example[fig:OR-ex]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">OR bitwise operation example<span id="fig:OR-ex" label="fig:OR-ex">[fig:OR-ex]</span></figcaption>
</figure>
<p>In the figure <a href="#fig:OR-ex" data-reference-type="ref" data-reference="fig:OR-ex">4</a> you can see that we apply the or operation bit by bit. Starting from the right : <span v-highlightjs><code class="go" v-pre style="display: inline">0 | 0 = 0</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">0 | 0 = 0</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">0 | 1 = 1</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">1 | 0 = 1</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">0 | 0 = 0</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">0 | 1 = 1</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">1 | 1 = 1</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">0 | 1 = 1</code></span> When we use the base ten notation for the operands and the result, it does not make sense. Let’s use the binary notation :</p>
<pre v-highlightjs><code class="go" v-pre >11001000 | 01100100 = 11101100</code></pre>
<p>With OR, we set to 1 the bits of the left operand that are set to 1 in the right operand. (you can switch left and right, and this will also work).</p>
<div id="xor-operator" class="anchor"></div>
<h3 data-number="10.2.3"><span class="header-section-number">10.2.3</span> XOR operator : <span v-highlightjs><code class="go" v-pre style="display: inline">^</code></span> <a href="#xor-operator"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>The XOR abbreviation stands for e <strong>X</strong>clusive <strong>OR</strong>. Why do we need to add another or? Is the one that we defined before not sufficient? Remember that with <strong>OR</strong> we have the following result <span v-highlightjs><code class="go" v-pre style="display: inline">1 | 1 = 1</code></span>.</p>
<p>When both operands are true, the result is true. Sometimes this behavior isn’t acceptable. We want maybe to exclude that case.</p>
<p>With the exclusive or : <span v-highlightjs><code class="go" v-pre style="display: inline">1 ^ 1 = 0</code></span>. To be more precise, we have the following rule that applies: <span v-highlightjs><code class="go" v-pre style="display: inline">x ^ y</code></span> <strong>is equal to 1 only if one of the operands is equal to 1, not both.</strong></p>
<pre v-highlightjs><code class="go" v-pre >// enum-iota-bitmasks/bitwise-xor/main.go 

var x, y, z uint8
x = 200 // 11001000
fmt.Printf(&quot;%08b\n&quot;, x)

y = 100 // 01100100
fmt.Printf(&quot;%08b\n&quot;, y)

z = x ^ y

// print in binary
fmt.Printf(&quot;%08b\n&quot;, z)
// 10101100

// print in base 10
fmt.Printf(&quot;%d\n&quot;, z)
// 172</code></pre>
<p>The XOR set to 0 the bits that are identical in the two operands and set to 1 the bits that are different.</p>
<div id="not-operator" class="anchor"></div>
<h3 data-number="10.2.4"><span class="header-section-number">10.2.4</span> NOT Operator : <span v-highlightjs><code class="go" v-pre style="display: inline">^</code></span> <a href="#not-operator"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>The NOT operator is the same as the XOR operator except that we use it in front of only one operand. It’s pretty easy to remember because this operator will reverse the values that will be stored by each bit :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">^0 = 1</code></span></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">^1 = 0</code></span></p></li>
</ul>
<p>NOT will invert the values of the bits. Let’s take an example :</p>
<pre v-highlightjs><code class="go" v-pre >// enum-iota-bitmasks/bitwise-not/main.go 

var x, z uint8
x = 200 // 11001000
fmt.Printf(&quot;%08b\n&quot;, x)

z = ^x

// print in binary
fmt.Printf(&quot;%08b\n&quot;, z)
// 00110111

// print in base 10
fmt.Printf(&quot;%d\n&quot;, z)
// 55</code></pre>
<figure>
<b-img :src="require('@/assets/images/not_bitwise.png')" alt="NOT bitwise operation example[fig:NOT-bitwise-operation]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">NOT bitwise operation example<span id="fig:NOT-bitwise-operation" label="fig:NOT-bitwise-operation">[fig:NOT-bitwise-operation]</span></figcaption>
</figure>
<div id="and-not-operator-bit-clear" class="anchor"></div>
<h3 data-number="10.2.5"><span class="header-section-number">10.2.5</span> AND NOT operator : <span v-highlightjs><code class="go" v-pre style="display: inline">&amp;^</code></span> (bit clear) <a href="#and-not-operator-bit-clear"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>The AND NOT operator is used to set to zero the bits of the left operand if the corresponding bit in the right operand is set to 1. This might be obscure, so I suggest you to take a look at the figure <a href="#fig:AND-NOT" data-reference-type="ref" data-reference="fig:AND-NOT">6</a>.</p>
<figure>
<b-img :src="require('@/assets/images/and_not_bitwise.png')" alt="AND NOT bitwise operation example[fig:AND-NOT]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">AND NOT bitwise operation example<span id="fig:AND-NOT" label="fig:AND-NOT">[fig:AND-NOT]</span></figcaption>
</figure>
<p>This operator mixes the operator AND and NOT. We can decompose it :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">x AND NOT y</code></span> is equivalent to :</p></li>
<li><p>x AND (NOT y)</p></li>
</ul>
<p>Let’s try to obtain the same result as before with this decomposition :</p>
<p>Let’s denote :</p>
<ul>
<li><p>x = 11001000</p></li>
<li><p>y = 11111111</p></li>
<li><p>NOT y = 00000000</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">x AND NOT y</code></span> is equivalent to</p></li>
<li><p>11001000 AND 00000000 = 00000000</p></li>
</ul>
<div id="left-and-right-shift" class="anchor"></div>
<h3 data-number="10.2.6"><span class="header-section-number">10.2.6</span> Left and right shift (<span v-highlightjs><code class="go" v-pre style="display: inline">&lt;&lt;</code></span> , <span v-highlightjs><code class="go" v-pre style="display: inline">&gt;&gt;</code></span> ) <a href="#left-and-right-shift"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Those operators are used to <strong>move</strong> the bits to the left or to the right.</p>
<p>You have to specify the number of positions by which the bits will be shifted.</p>
<div id="left-shift" class="anchor"></div>
<h4 data-number="10.2.6.1"><span class="header-section-number">10.2.6.1</span> Left shift <a href="#left-shift"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >// enum-iota-bitmasks/bitwise-left-shift/main.go 

var x, n, z uint8
x = 200 // 11001000
fmt.Printf(&quot;%08b\n&quot;, x)

// number of positions
n = 1
z = x &lt;&lt; n
// print in binary
fmt.Printf(&quot;%08b\n&quot;, z)
// 10010000

// print in base 10
fmt.Printf(&quot;%d\n&quot;, z)
// 144</code></pre>
<p>In the previous example, we are left-shifting the byte 11001000 by one position. The result of this shift is 1001000<strong>0.</strong> We have added a zero at the left of the byte and shifted the other by 1 position as you can see in the figure <a href="#fig:LEFT-SHIFT-bitwise" data-reference-type="ref" data-reference="fig:LEFT-SHIFT-bitwise">7</a>.</p>
<figure>
<b-img :src="require('@/assets/images/left_shift.png')" alt="LEFT SHIFT bitwise operation example[fig:LEFT-SHIFT-bitwise]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">LEFT SHIFT bitwise operation example<span id="fig:LEFT-SHIFT-bitwise" label="fig:LEFT-SHIFT-bitwise">[fig:LEFT-SHIFT-bitwise]</span></figcaption>
</figure>
<p>We are storing our integers into 8 bits, so we are losing bits. to avoid that, we can store our numbers on 16 bits (2 bytes) :</p>
<pre v-highlightjs><code class="go" v-pre >var x, n, z uint16
x = 200 // 11001000
fmt.Printf(&quot;%08b\n&quot;, x)

// number of positions
n = 1
z = x &lt;&lt; n
// print in binary
fmt.Printf(&quot;%b\n&quot;, z)
// 110010000

// print in base 10
fmt.Printf(&quot;%d\n&quot;, z)
// 400</code></pre>
<p>You can note something interesting; we have multiplied our number by 2.</p>
<pre v-highlightjs><code class="go" v-pre >200 &lt;&lt; 1 = 400</code></pre>
<p>When you <strong>left shift</strong> the binary representation of a number by n position you <strong>multiply</strong> it’s decimal representation bt it by two at the power n.</p>
<p>For instance, if you left shift by 3, you will multiply your number by two at the power three, which is 8 :</p>
<pre v-highlightjs><code class="go" v-pre >var x, n, z uint16
x = 200 // 11001000
fmt.Printf(&quot;%08b\n&quot;, x)

// number of positions
n = 3
z = x &lt;&lt; n
// print in binary
fmt.Printf(&quot;%b\n&quot;, z)
// 11001000000

// print in base 10
fmt.Printf(&quot;%d\n&quot;, z)
// 1600</code></pre>
<div id="right-shift" class="anchor"></div>
<h4 data-number="10.2.6.2"><span class="header-section-number">10.2.6.2</span> Right Shift <a href="#right-shift"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>The operator &gt;&gt; allow you to shifts the bits to the right :</p>
<pre v-highlightjs><code class="go" v-pre >// enum-iota-bitmasks/bitwise-right-shift/main.go 

var x, n, z uint8
x = 200 // 11001000
fmt.Printf(&quot;%08b\n&quot;, x)

// number of positions
n = 3
z = x &gt;&gt; n
// print in binary
fmt.Printf(&quot;%08b\n&quot;, z)
// 00011001

// print in base 10
fmt.Printf(&quot;%d\n&quot;, z)
// 25</code></pre>
<p>You can see here that we are dividing 200 by 3 when we shift the bytes to the right. This is another property of binary numbers. When you left shift the binary representation of a number (in base 10) by n position, you divide it by two at the power n.</p>
<div id="bitmasks-advancedsecbitmasks" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> Bitmasks (advanced)<span id="sec:Bitmasks" label="sec:Bitmasks">[sec:Bitmasks]</span> <a href="#bitmasks-advancedsecbitmasks"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Imagine that you are building a function that requires eight boolean values to be configured :</p>
<ol type="1">
<li><p>Is verbose mode activated?</p></li>
<li><p>Is configuration loaded from disk?</p></li>
<li><p>Is database connexion required?</p></li>
<li><p>Is logger activated?</p></li>
<li><p>Is debug mode activated?</p></li>
<li><p>Is support for float activated?</p></li>
<li><p>Is recovery mode activated?</p></li>
<li><p>Reboot on failure?</p></li>
</ol>
<p>What we can do instead is passing just an integer value. This integer value can represent the configuration.</p>
<p>For instance, 01110001 (113 in base 10) will represent the following configuration :</p>
<ol type="1">
<li><p>Activated (1)</p></li>
<li><p>Deactivated (0)</p></li>
<li><p>Deactivated (0)</p></li>
<li><p>Deactivated (0)</p></li>
<li><p>Activated (1)</p></li>
<li><p>Activated (1)</p></li>
<li><p>Activated (1)</p></li>
<li><p>Deactivated (0)</p></li>
</ol>
<p>Remember that when bytes are involved, we read from right to left.</p>
<p>What are the advantages?</p>
<ul>
<li><p>We improve the code readability; we pass only an argument instead of 8</p></li>
<li><p>We spare memory. If we had eight booleans, we have 8*8 bits = 64 bits used compared to only 8.</p></li>
</ul>
<p>We will use the left shift bitwise operation. The idea is the first configuration value is equal to 1 (00000001), and then for each new configuration variable, we will left shift the bit :</p>
<pre v-highlightjs><code class="go" v-pre >type MyConf uint8

const (
    VERBOSE MyConf = 1 &lt;&lt; iota
    CONFIG_FROM_DISK
    DATABASE_REQUIRED
    LOGGER_ACTIVATED
    DEBUG
    FLOAT_SUPPORT
    RECOVERY_MODE
    REBOOT_ON_FAILURE
)</code></pre>
<p>The values that are generated by this constant declaration are the following :</p>
<ul>
<li><p>VERBOSE : 00000001</p></li>
<li><p>CONFIG_FROM_DISK : 00000010</p></li>
<li><p>DATABASE_REQUIRED : 00000100</p></li>
<li><p>LOGGER_ACTIVATED : 00001000</p></li>
<li><p>DEBUG : 00010000</p></li>
<li><p>FLOAT_SUPPORT : 00100000</p></li>
<li><p>RECOVERY_MODE : 01000000</p></li>
<li><p>REBOOT_ON_FAILURE : 10000000</p></li>
</ul>
<p>We are left shifting the byte for each new constant by an iota. Remember that iota’s value increases when there is a new constant declared. The code listing above is equivalent to :</p>
<pre v-highlightjs><code class="go" v-pre >// enum-iota-bitmasks/flags/main.go 

const (
    VERBOSE           MyConf = 1 &lt;&lt; 0
    CONFIG_FROM_DISK  MyConf = 1 &lt;&lt; 1
    DATABASE_REQUIRED MyConf = 1 &lt;&lt; 2
    LOGGER_ACTIVATED  MyConf = 1 &lt;&lt; 3
    DEBUG             MyConf = 1 &lt;&lt; 4
    FLOAT_SUPPORT     MyConf = 1 &lt;&lt; 5
    RECOVERY_MODE     MyConf = 1 &lt;&lt; 6
    REBOOT_ON_FAILURE MyConf = 1 &lt;&lt; 7
)</code></pre>
<div id="configuration-usage" class="anchor"></div>
<h5 data-number="11.0.0.0.1"><span class="header-section-number">11.0.0.0.1</span> Configuration usage <a href="#configuration-usage"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h5>
<p>Now it’s time to use our configuration enum. The function that we will use is the following one :</p>
<pre v-highlightjs><code class="go" v-pre >// enum-iota-bitmasks/flags/main.go 

func MyComplexFunction(conf MyConf, databaseDsn string) {
    //...
}</code></pre>
<p>The configuration is now just a single argument of type MyConf (which is an uint8 behind).</p>
<p>When we call our function, we just have to provide the required flags :</p>
<pre v-highlightjs><code class="go" v-pre >MyComplexFunction(VERBOSE|REBOOT_ON_FAILURE, &quot;mysql...&quot;)</code></pre>
<p>Here we tell our function that we want to activate the verbose mode and reboot on failure. The value of VERBOSE|REBOOT_ON_FAILURE is equal to 10000001. Only two bits are set to 1, the first (VERBOSE) and the last (REBOOT_ON_FAILURE).</p>
<div id="reading-a-configuration-variable" class="anchor"></div>
<h5 data-number="11.0.0.0.2"><span class="header-section-number">11.0.0.0.2</span> Reading a configuration variable <a href="#reading-a-configuration-variable"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h5>
<p>To check if a flag is set, we can use the AND bitwise operation. For instance, if we want to check if the bit corresponding to REBOOT_ON_FAILURE is set in <strong>conf</strong>the following test can be used :</p>
<pre v-highlightjs><code class="go" v-pre >conf &amp; REBOOT_ON_FAILURE != 0</code></pre>
<p>If the previous expression is true, it means that the flag is set.</p>
<p>Let’s take an example :</p>
<pre v-highlightjs><code class="go" v-pre >// enum-iota-bitmasks/flags/main.go 

func main(){
    MyComplexFunction(VERBOSE|REBOOT_ON_FAILURE, &quot;test&quot;)
}
func MyComplexFunction(conf MyConf, databaseDsn string) {
    fmt.Printf(&quot;conf : %08b\n&quot;, conf)
    test := conf &amp; REBOOT_ON_FAILURE
    fmt.Printf(&quot;test : %08b\n&quot;, test)
}</code></pre>
<p>The execution of the previous program will output :</p>
<pre v-highlightjs><code class="go" v-pre >conf : 10000001
test : 10000000</code></pre>
<p>The variable test is different of 0 (00000000 as a byte). It seems to work. Let’s take another example :</p>
<pre v-highlightjs><code class="go" v-pre >test2 := conf &amp; CONFIG_FROM_DISK</code></pre>
<p>is equal to 00000000. The flag is not set.</p>
<div id="toggle-a-specific-bitflag" class="anchor"></div>
<h5 data-number="11.0.0.0.3"><span class="header-section-number">11.0.0.0.3</span> Toggle a specific bitflag <a href="#toggle-a-specific-bitflag"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h5>
<p>The operation to use here is the XOR. For instance, imagine that you have a config variable set, but you wish to activate a deactivated flag (or deactivate an activated flag) :</p>
<pre v-highlightjs><code class="go" v-pre >// toogle FLOAT_SUPPORT =&gt; Activate
conf = conf ^ FLOAT_SUPPORT
test = conf &amp; FLOAT_SUPPORT
fmt.Printf(&quot;test : %08b\n&quot;, test)</code></pre>
<p>The previous snippet will output :</p>
<pre v-highlightjs><code class="go" v-pre >test 00100000</code></pre>
<p>The flag has been toggled; the test says that it is now activated.</p>
<div id="clear-a-specific-bitflag" class="anchor"></div>
<h5 data-number="11.0.0.0.4"><span class="header-section-number">11.0.0.0.4</span> Clear a specific bitflag <a href="#clear-a-specific-bitflag"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h5>
<p>To clear a flag, the bitwise operation to use is AND NOT. Here is an example where FLOAT_SUPPORT has been previously activated :</p>
<pre v-highlightjs><code class="go" v-pre >conf = conf &amp;^ FLOAT_SUPPORT
test = conf &amp; FLOAT_SUPPORT
fmt.Printf(&quot;test : %08b\n&quot;, test)</code></pre>
<p>Will output <strong>00000000</strong>, which means that the flag has been cleared from <strong>conf.</strong></p>
<div id="summary" class="anchor"></div>
<h4 data-number="11.0.0.1"><span class="header-section-number">11.0.0.1</span> Summary <a href="#summary"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<figure>
<b-img :src="require('@/assets/images/bitflags.png')" alt="Go is a compiled language"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Go is a compiled language</figcaption>
</figure>
<div id="test-yourself" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="12.1"><span class="header-section-number">12.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >//snippet 1
type Elem uint8

const (
    OptB Elem = iota + 5
    OptA
    OptC
)</code></pre>
<ol type="1">
<li><p>True or False. Go version 1 supports enum types.</p></li>
<li><p>What is the value of <span v-highlightjs><code class="go" v-pre style="display: inline">OptA</code></span> in snippet 1 (see below)?</p></li>
<li><p>What are the binary operators for AND, OR, XOR, NOT, AND NOT?</p></li>
<li><p>What is the type of <span v-highlightjs><code class="go" v-pre style="display: inline">OptC</code></span>?</p></li>
<li><p>What is the operator to shift to the left a binary digit?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="12.2"><span class="header-section-number">12.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>True or False. Go version 1 supports enum types.</p>
<ol type="1">
<li><p>False</p></li>
<li><p>When you want to create an enum type, you will need to write additional methods</p></li>
</ol></li>
<li><p>What is the value of <span v-highlightjs><code class="go" v-pre style="display: inline">OptA</code></span> in snippet 1 (see below)?</p>
<ol type="1">
<li>6 (1+5)</li>
</ol></li>
<li><p>What are the binary operators for AND, OR, XOR, NOT, AND NOT?</p>
<ol type="1">
<li><p>AND : <span v-highlightjs><code class="go" v-pre style="display: inline">&amp;</code></span></p></li>
<li><p>OR : <span v-highlightjs><code class="go" v-pre style="display: inline">|</code></span></p></li>
<li><p>XOR : <span v-highlightjs><code class="go" v-pre style="display: inline">^</code></span></p></li>
<li><p>NOT : <span v-highlightjs><code class="go" v-pre style="display: inline">^</code></span></p></li>
<li><p>AND NOT : <span v-highlightjs><code class="go" v-pre style="display: inline">&amp; ^</code></span></p></li>
</ol></li>
<li><p>What is the type of <span v-highlightjs><code class="go" v-pre style="display: inline">OptC</code></span>?</p>
<ol type="1">
<li><span v-highlightjs><code class="go" v-pre style="display: inline">Elem</code></span></li>
</ol></li>
<li><p>What is the operator to shift to the left a binary digit ?</p>
<ol type="1">
<li><span v-highlightjs><code class="go" v-pre style="display: inline">&gt;&gt;</code></span></li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> Key takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>An enumeration type (or enum) is a data type that is composed of a set of values explicitly defined</p>
<ul>
<li><p>Ex: The days of the week</p></li>
<li><p>Ex: The months of the year</p></li>
<li><p>Ex: The statuses of an e-commerce order (Created, Locked, Paid, Shipped, ...)</p></li>
</ul></li>
<li><p>Go version 1 does not support enumeration types out of the box</p></li>
<li><p>However, you can follow this recipe to create an enum <span v-highlightjs><code class="go" v-pre style="display: inline">E</code></span></p>
<ul>
<li><p>Create a new type <span v-highlightjs><code class="go" v-pre style="display: inline">E</code></span>.</p></li>
<li><p>The underlying type of <span v-highlightjs><code class="go" v-pre style="display: inline">E</code></span> is <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span></p></li>
<li><p>Define a set of constants of type <span v-highlightjs><code class="go" v-pre style="display: inline">E</code></span></p></li>
<li><p>The first constant has the value <span v-highlightjs><code class="go" v-pre style="display: inline">0</code></span>, the second <span v-highlightjs><code class="go" v-pre style="display: inline">1</code></span>, .…</p></li>
<li><p>Each constant is an enum element. They need to be named accordingly.</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">E</code></span> needs to implement the following interfaces <span v-highlightjs><code class="go" v-pre style="display: inline">json.Marshaler, json.Unmarshaler, fmt.Stringer</code></span> (if you use JSON, if you use another marshaling format in your application, you will need to implement the required interfaces).</p></li>
<li><p>You can use a library to generate those implementations for you.</p></li>
</ul></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">iota</code></span> is a predeclared identifier. It represents successive untyped <strong>integer</strong> constants.</p></li>
<li><p>We can use it in a “grouped” declaration of constants.</p></li>
<li><p>Its value starts at zero and is equal to the index of the constant.</p></li>
<li><p>A bit is a binary digit (0 or 1)</p></li>
<li><p>A byte is 8 bits.</p></li>
<li><p>Go has bitwise logical and shift operators. We can use them <strong>only with integers</strong></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">&amp;</code></span> : AND</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">|</code></span> :OR</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">^</code></span> :XOR</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">&amp;^</code></span> :AND NOT</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">&lt;&lt;</code></span> :left shift</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">&gt;&gt;</code></span> :right shift</p></li>
<li><p>An <span v-highlightjs><code class="go" v-pre style="display: inline">uint8</code></span> is stored in memory with 8 bits</p></li>
<li><p>You can use an <span v-highlightjs><code class="go" v-pre style="display: inline">uint8</code></span> to pass a set of 8 boolean arguments to a function or method</p>
<ul>
<li>We can use bitwise operators to check the value of those boolean arguments.</li>
</ul></li>
</ul>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap27EnumIotaAndBitmask"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap26BasicHttpServer'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Basic HTTP Server</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap28DatesAndTime'}">
									<p><u><small>Next</small></u></p>
									<p><small>Dates and time</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Enum, Iota & Bitmask - Practical Go Lessons"
const description = "An “enum” (or enumeration data type) is a data type that consists of a set of “values that are explicitly defined by the programmer”. We will see how to create enums in Go"

export default {
  name: "Chap27EnumIotaAndBitmask",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

mounted() {
    const mathElements = window.document.getElementsByClassName("math");
    const macros = [];
    for (var i = 0; i < mathElements.length; i++) {
      const texText = mathElements[i].firstChild;
      if (mathElements[i].tagName === "SPAN") {
 		window.katex.render(texText.data, mathElements[i], {
          displayMode: mathElements[i].classList.contains('display'),
          throwOnError: true,
          macros: macros,
          fleqn: false
        });
      }
    }
  },

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
